<template>
    <table class="width-full table-auto">
        <tr class="text-main bg-gray-100">
            <th v-for="column in tableData[0]" class="border-gray-200 px-5 py-3">{{ column.short_name }}</th>
        </tr>
        <tr v-for="row in tableData">
            <td v-for="column in row" class="border-gray-200 px-5 py-3">{{ column.value }}</td>
        </tr>
    </table>
</template>

<script>
export default {
    props: {
        tableColumns: Array,
        tableData: Array,
    },
    mounted: function() {
        //
    }
};
</script>

<style scoped>
    table {
        border-collapse: separate;
        border-spacing: 0;
        min-width: 350px;
    }
    table tr th,
    table tr td {
        border-right-width: 2px;
        border-bottom-width: 2px;
    }
    table tr th:first-child,
    table tr td:first-child {
        border-left-width: 2px;
    }
    table tr th {
        border-top-width: 2px;
    }

    /* top-left border-radius */
    table tr:first-child th:first-child {
        border-top-left-radius: 6px;
    }

    /* top-right border-radius */
    table tr:first-child th:last-child {
        border-top-right-radius: 6px;
    }

    /* bottom-left border-radius */
    table tr:last-child td:first-child {
        border-bottom-left-radius: 6px;
    }

    /* bottom-right border-radius */
    table tr:last-child td:last-child {
        border-bottom-right-radius: 6px;
    }
</style>
