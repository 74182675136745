<template>
<!--    <keep-alive>-->
        <component :is="currentComponent" :swap-component="swapComponent" :childData="childData"></component>
<!--    </keep-alive>-->
</template>

<script>
import EmailSchedule from './EmailSchedule.vue'
import CreateEmailSchedule from './CreateEmailSchedule.vue'

export default {
    props: [
        'component',
    ],
    data: function() {
        return {
            currentComponent: this.component,
            childData:  {
                isCreate: true,
            },
        }
    },
    components: {
        'email-schedule': EmailSchedule,
        'create-email-schedule': CreateEmailSchedule,
    },
    computed: {

    },
    watch: {

    },
    mounted: function() {

    },
    methods: {
        closeModal: function () {

        },
        swapComponent: function (component, schedule, isCreate) {
            this.childData = {
                schedule: schedule,
                isCreate: isCreate,
            };
            this.currentComponent = component;
        },
    },
};
</script>

<style scoped>

</style>
