<script setup lang="ts">
</script>

<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="22" fill="#929292" />
    <g id="Pricing Rate Codes Mockup">
      <g id="Phase 2 - 1920x1080">
        <rect
          width="1746"
          height="1080"
          transform="translate(-863 -445)"
          fill="white"
        />
        <g id="Sidebar/Content" />
        <g id="Test Container">
          <rect
            width="1746"
            height="1080"
            transform="translate(-863 -445)"
            fill="black"
            fill-opacity="0.48"
          />
          <g id="Modal" filter="url(#filter0_d_0_1)">
            <g clip-path="url(#clip0_0_1)">
              <rect
                x="-502"
                y="-749"
                width="1024"
                height="1688"
                rx="16"
                fill="white"
              />
              <rect
                x="-502"
                y="-749"
                width="1024"
                height="1688"
                rx="16"
                fill="black"
                fill-opacity="0.04"
              />
              <g id="Frame 512" clip-path="url(#clip1_0_1)">
                <g id="Frame 520">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.97 0.460307C14.1106 0.319857 14.3013 0.240967 14.5 0.240967C14.6988 0.240967 14.8894 0.319857 15.03 0.460307L19.53 4.96031C19.6705 5.10093 19.7494 5.29156 19.7494 5.49031C19.7494 5.68906 19.6705 5.87968 19.53 6.02031L15.03 10.5203C14.9614 10.594 14.8785 10.6531 14.7866 10.6941C14.6946 10.7351 14.5952 10.7571 14.4945 10.7589C14.3938 10.7607 14.2938 10.7422 14.2004 10.7044C14.107 10.6667 14.0222 10.6106 13.951 10.5393C13.8798 10.4681 13.8236 10.3833 13.7859 10.2899C13.7482 10.1965 13.7296 10.0965 13.7314 9.99578C13.7332 9.89508 13.7552 9.79577 13.7962 9.70377C13.8372 9.61177 13.8963 9.52897 13.97 9.46031L17.19 6.24031H5.50001C5.3011 6.24031 5.11033 6.16129 4.96968 6.02064C4.82903 5.87998 4.75001 5.68922 4.75001 5.49031C4.75001 5.29139 4.82903 5.10063 4.96968 4.95998C5.11033 4.81932 5.3011 4.74031 5.50001 4.74031H17.19L13.97 1.52031C13.8296 1.37968 13.7507 1.18906 13.7507 0.990307C13.7507 0.791556 13.8296 0.600932 13.97 0.460307ZM6.03001 11.4603C6.17046 11.6009 6.24935 11.7916 6.24935 11.9903C6.24935 12.1891 6.17046 12.3797 6.03001 12.5203L2.81001 15.7403H14.5C14.6989 15.7403 14.8897 15.8193 15.0303 15.96C15.171 16.1006 15.25 16.2914 15.25 16.4903C15.25 16.6892 15.171 16.88 15.0303 17.0206C14.8897 17.1613 14.6989 17.2403 14.5 17.2403H2.81001L6.03001 20.4603C6.1037 20.529 6.1628 20.6118 6.20379 20.7038C6.24478 20.7958 6.26683 20.8951 6.2686 20.9958C6.27038 21.0965 6.25186 21.1965 6.21413 21.2899C6.17641 21.3833 6.12027 21.4681 6.04905 21.5393C5.97783 21.6106 5.893 21.6667 5.79961 21.7044C5.70622 21.7422 5.60619 21.7607 5.50549 21.7589C5.40479 21.7571 5.30547 21.7351 5.21347 21.6941C5.12147 21.6531 5.03867 21.594 4.97001 21.5203L0.470011 17.0203C0.329561 16.8797 0.250671 16.6891 0.250671 16.4903C0.250671 16.2916 0.329561 16.1009 0.470011 15.9603L4.97001 11.4603C5.11064 11.3199 5.30126 11.241 5.50001 11.241C5.69876 11.241 5.88939 11.3199 6.03001 11.4603Z"
                    fill="black"
                    fill-opacity="0.32"
                  />
                </g>
              </g>
            </g>
            <rect
              x="-501.5"
              y="-748.5"
              width="1023"
              height="1687"
              rx="15.5"
              stroke="black"
              stroke-opacity="0.16"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_0_1"
        x="-538"
        y="-777"
        width="1096"
        height="1760"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="18" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_0_1">
        <rect
          x="-502"
          y="-749"
          width="1024"
          height="1688"
          rx="16"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="1024"
          height="1508"
          fill="white"
          transform="translate(-502 -653)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
