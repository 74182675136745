/* eslint-disable camelcase */
import { reactive } from 'vue';
import { DigitalVendor, DigitalVendorEndpoint } from '@apparatix/types';

interface EndpointType {
  name: string;
  value: string;
}

type EndpointTypes = Array<EndpointType>;

export const DigitalVendorEndpointStore = reactive({
  vendors: [] as Array<DigitalVendor>,
  activeEndpoint: {} as DigitalVendorEndpoint,
  validEndpointTypes: [] as EndpointTypes,
  setVendors(vendorList: Array<DigitalVendor>): void {
    this.vendors = vendorList;
  },
  getEmptyEndpointModel(vendorId: number): DigitalVendorEndpoint {
    return {
      digital_vendor_id: vendorId,
      base_url: '',
      organization: '',
      client_id: '',
      client_secret: '',
      username: '',
      password: '',
      api_key: '',
      swap_key: '',
      type: '',
    };
  },
  getVendorById(id: number): DigitalVendor {
    return this.vendors.find(vendor => vendor.id === id);
  },
  getVendorByActiveEndpoint(): DigitalVendor {
    if(!this.activeEndpoint.digital_vendor_id) return null;
    return this.getVendorById(this.activeEndpoint.digital_vendor_id);
  },
  setAvailableEndpointTypes(types: EndpointTypes): void {
    if(!types.length) return;
    const availableTypes = [];

    types.forEach(type => {
      availableTypes.push({ name: type, value: type });
    });

    this.validEndpointTypes = availableTypes;
  },
  addEndpointToList(endpoint: DigitalVendorEndpoint): void {
    const parent = this.getVendorById(endpoint.digital_vendor_id);
    if(!parent) return;

    if(!Array.isArray(parent.digital_vendor_endpoints)) {
      parent.digital_vendor_endpoints = [];
    }

    parent.digital_vendor_endpoints.push(endpoint);
  },
  removeEndpointFromList(endpoint: DigitalVendorEndpoint): void {
    const parent = this.getVendorById(endpoint.digital_vendor_id);
    if(!parent) return;

    const idx = parent.digital_vendor_endpoints.findIndex(item => item.id === endpoint.id);
    if (idx === -1) return;
    parent.digital_vendor_endpoints.splice(idx, 1);
  },
  updateEndpoint(endpoint: DigitalVendorEndpoint): void {
    const parent = this.getVendorById(endpoint.digital_vendor_id);
    if(!parent) return;
    const idx = parent.digital_vendor_endpoints.findIndex(item => item.id === endpoint.id);
    if (idx === -1) return;
    parent.digital_vendor_endpoints[idx] = endpoint;
  },
  setActiveEndpoint(endpoint: DigitalVendorEndpoint): void {
    this.activeEndpoint = endpoint;
  },
  clearActiveEndpoint(): void {
    this.activeEndpoint = {};
  },
});
