<script setup lang="ts">
import { computed, ref } from 'vue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';
import Modal from '@apparatix/Components/Modal.vue';
import PricingRateCode from '@apparatix/Components/PricingRateCode.vue';
import FormSkeleton from '@apparatix/Components/FormSkeleton.vue';
import PricingRateCodeAssignment from '@apparatix/Components/PricingRateCodeAssignment.vue';

const TOAST_LIFE = 3000;

interface YieldCodeProps {
  rateCodes: string;
}

const props = defineProps<YieldCodeProps>();

const rateCodeArray = ref(JSON.parse(props.rateCodes));

interface CreateYieldCodeProps {
  faceList: any;
  pricingRateCode: {id?: string, name?: string, description?: string};
  pricingRules: any[];
  selectedRules: any[];
}

interface YieldCodeState {
  name?: string;
  description?: string;
  rules?: any[];
}

const confirm = useConfirm();
const toast = useToast();

const modalVisible = ref(false);
const assignFaceModalVisible = ref(false);
const modalFormRef = ref();
const action = ref<'create' | 'edit' | null>(null);
const isLoading = ref(false);
const yieldCodeViewModel = ref<CreateYieldCodeProps>();
const previousState = ref<YieldCodeState>();
const selectedRow = ref<{name: string, id: string, rowId: number}>({ name: '', id: undefined, rowId: undefined });
const continueAfterSave = ref(true);
const recordId = ref();

const yieldCodeName = ref();
const yieldCodeDescription = ref();
const rulesToSave = ref([]);

const assignedSet = ref(new Set<number>());
const unassignedSet = ref(new Set<number>());

const shouldShowYieldCodeComponent = computed(() => {
  if(isLoading.value) return false;
  if(!action.value) return false;
  if(!yieldCodeViewModel.value) return false;

  return true;
});

const updateViewModel = (newValues: Partial<CreateYieldCodeProps>) => {
  yieldCodeViewModel.value = { ...yieldCodeViewModel.value, ...newValues };
};

const updatePreviousState = (newValues: YieldCodeState) => {
  previousState.value = { ...previousState.value, ...newValues };
};

const modalHeaderText = computed(() => {
  if(!action.value) return null;

  return action.value === 'create' ? 'Create Yield Code' : 'Edit Yield Code';
});

const canSubmit = computed(() => yieldCodeName.value && rulesToSave.value.length > 0);

const hasEditMadeChanges = computed(() => {
  if(action.value === 'create' || !yieldCodeViewModel.value) return false;

  if(rulesToSave.value.length !== previousState.value?.rules?.length) {
    return true;
  }

  let haveRulesChanged = false;

  previousState.value.rules.forEach((rule, idx) => {
    if(rule.id !== rulesToSave.value[idx].id) {
      haveRulesChanged = true;
    }
  });

  return haveRulesChanged;
});

const shouldDisableEdit = computed(() => {
  if(!yieldCodeName.value || rulesToSave.value.length === 0) return true;

  return false;
});

const onClose = () => cleanup();

const onCreateClick = async () => {
  isLoading.value = true;
  modalVisible.value = true;
  action.value = 'create';

  const data = await axios.get('/pricingRateCodes/create?returnJson=true');
  if(data) {
    isLoading.value = false;

    updateViewModel(data.data);

    updatePreviousState({
      name: '',
      description: '',
      rules: [],
    });
  }
};

const onEditClick = async (rateCode) => {
  modalVisible.value = true;
  action.value = 'edit';
  isLoading.value = true;
  selectedRow.value = { id: rateCode.id, name: rateCode.name, rowId: 0 };

  const data = await axios.get(`/pricingRateCodes/${selectedRow.value.id}?returnJson=true`);

  if(data.status !== 200) {
    const message = data.data?.message ?? 'Something went wrong';
    toast.add({ severity: 'error', summary: 'Error', detail: message, life: TOAST_LIFE });
  }

  const { selectedRules, pricingRateCode } = data.data as CreateYieldCodeProps;
  if(data?.data) {
    isLoading.value = false;
    recordId.value = data.data.pricingRateCode.id;

    updateViewModel(data.data);

    updatePreviousState({
      name: pricingRateCode.name,
      description: pricingRateCode.description,
      rules: selectedRules,
    });
  }
};

const onAssignClick = async (rateCode) => {
  selectedRow.value = { id: rateCode.id, name: rateCode.name, rowId: 0 };
  assignFaceModalVisible.value = true;
};

const deleteCode = async () => {
  try {
    const result = await axios.delete(`/pricingRateCodes/${selectedRow.value.id}`);
    if(result.status === 200) {
      rateCodeArray.value = rateCodeArray.value.filter(code => code.id !== selectedRow.value.id);
      toast.add({ severity: 'success', summary: 'Success', detail: 'Code successfully deleted', life: TOAST_LIFE });
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error deleting code', life: TOAST_LIFE });
    }
  } catch(error) {
    console.log('caught error', error);
    if (error.response?.data?.message === 'THIS YIELD CODE IS CURRENTLY IN USE') {
      toast.add({
        severity: 'error',
        summary: 'Yield Code in use',
        detail: 'This yield code is currently assigned to a face, and cannot be deleted. If you want to delete this code, first unassign this code from any faces',
      });
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'Error deleting code', life: TOAST_LIFE });
    }
  }
};

const onDeleteClick = (rateCode) => {
  selectedRow.value = { id: rateCode.id, name: rateCode.name, rowId: 0 };

  confirm.require({
    message: 'Are you sure you want to delete this yield code?',
    header: 'Confirm delete',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: () => deleteCode(),
  });
};

const onSave = async (openAssignModal = false) => {
  let success = false;

  const payload: {name: string, description: string, rules: any[], id?: string} = {
    name: yieldCodeName.value,
    description: yieldCodeDescription.value,
    rules: rulesToSave.value,
  };

  if(!recordId.value) {
    const result = await axios.post('/pricingRateCodes/', payload);

    if(result?.data?.id) {
      recordId.value = result.data.id;
      rateCodeArray.value.push({ id: result.data.id, name: yieldCodeName.value, description: yieldCodeDescription.value });
      success = true;
    }
  } else {
    payload.id = recordId.value;
    const result = await axios.put(`/pricingRateCodes/${recordId.value}`, payload);

    if(result.status === 200) {
      success = true;
      const changedCodeIndex = rateCodeArray.value.findIndex(code => code.id === recordId.value);
      rateCodeArray.value[changedCodeIndex].name = yieldCodeName.value;
      rateCodeArray.value[changedCodeIndex].description = yieldCodeDescription.value;

      toast.add({ severity: 'success', summary: 'Success', detail: 'Code saved', life: TOAST_LIFE });
    } else {
      const message = result.data?.message ?? 'Something went wrong';
      toast.add({ severity: 'error', summary: 'Error', detail: message, life: TOAST_LIFE });
    }
  }

  if(success && continueAfterSave.value) {
    action.value = 'edit';

    updatePreviousState({
      name: payload.name,
      description: payload.description,
      rules: payload.rules,
    });
  }

  if(!continueAfterSave.value || openAssignModal) {
    cleanup();

    if(openAssignModal) {
      onAssignClick({ id: payload.id, name: payload.name, rowId: 0 });
    }
  }

  if(modalFormRef.value) {
    modalFormRef.value.relevantDataPoints = {};
    modalFormRef.value.listOfRelevantActions = {};
    modalFormRef.value.selectedFace = {};
  }
};

const onAssignFaceClick = (event: MouseEvent) => {
  const message = `Assigning ${assignedSet.value.size} faces and unassigning ${unassignedSet.value.size} faces.`;

  if (assignedSet.value.size === 0 && unassignedSet.value.size === 0) {
    cleanup();
    return;
  }

  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: message,
    header: 'Are you sure?',
    accept: () => onAssignFaces(),
    position: 'center',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Ok',
    rejectLabel: 'Cancel',
    acceptClass: 'button-blue',
    rejectClass: 'text-blue',
  });
};

const onAssignFaces = async () => {
  const payload: {assignedFaces: any[], unassignedFaces: any[], id?: string} = {
    assignedFaces:  [...assignedSet.value],
    unassignedFaces: [...unassignedSet.value],
  };

  payload.id = selectedRow.value.id;
  const result = await axios.put('/pricingAssignCodes', payload);

  if(result.status === 200) {
    toast.add({ severity: 'success', summary: 'Success', detail: 'Faces Assigned', life: TOAST_LIFE });
  } else {
    const message = result.data?.message ?? 'Something went wrong';
    toast.add({ severity: 'error', summary: 'Error', detail: message, life: TOAST_LIFE });
  }

  cleanup();
};

const cleanup = () => {
  action.value = undefined;
  yieldCodeViewModel.value = undefined;
  rulesToSave.value = [];
  yieldCodeName.value = undefined;
  yieldCodeDescription.value = undefined;
  recordId.value = undefined;
  continueAfterSave.value = true;
  modalVisible.value = false;
  isLoading.value = false;
  previousState.value = {};
  assignFaceModalVisible.value = false;
  assignedSet.value.clear();
  unassignedSet.value.clear();
  selectedRow.value = { id: undefined, name: '', rowId: undefined };
};

</script>

<template>
  <section class="flex flex-col gap-y-8 mx-auto border-x border-slate-50 border-indigo-500" style="width: 100%; padding-left: 15px; padding-bottom: 60px; background: #f9fafb; height: 100%;">
    <DataTable
      striped-rows
      :value="rateCodeArray"
      class="p-datatable-sm"
    >
      <template #header>
        <div class="items-center">
          <h1 class="title">
            Yield Codes
          </h1>
          <Button
            v-tooltip.left="'Create a New Yield Code'"
            size="small"
            rounded
            outlined
            style="background:#2B9C74; color:white"
            @click="onCreateClick()"
          >
            <i class="pi pi-plus-circle" style="font-size: 1.5rem;" /> <div style="font-weight:700;">
              Add Yield Code
            </div>
          </Button>
        </div>
      </template>
      <template #empty>
        No Codes found.
      </template>
      <Column field="name" header="Name" />
      <Column field="description" header="Description" />
      <Column style="width: 80px">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Assign Code To Faces'"
            size="small"
            severity="info"
            rounded
            outlined
            label="Assign"
            @click="onAssignClick(slotProps.data)"
          />
        </template>
      </Column>
      <Column style="width: 20px">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Edit Code'"
            size="small"
            severity="info"
            class="icon-button"
            style="color:#2FA3EE"
            rounded
            outlined
            @click="onEditClick(slotProps.data)"
          >
            <i class="pi pi-pen-to-square" style="font-size: 1.5rem" />
          </Button>
        </template>
      </Column>
      <Column style="width: 20px">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Delete Code'"
            size="small"
            severity="danger"
            class="icon-button"
            style="color:#E11D48"
            rounded
            outlined
            @click="onDeleteClick(slotProps.data)"
          >
            <i class="pi pi-trash" style="font-size: 1.5rem" />
          </Button>
        </template>
      </Column>
    </DataTable>
  </section>

  <Modal
    :visible="modalVisible"
    :title="modalHeaderText"
    :subtitle="selectedRow.name"
    @on:close="onClose"
  >
    <template #body>
      <FormSkeleton v-if="isLoading" />
      <PricingRateCode
        v-if="shouldShowYieldCodeComponent"
        ref="modalFormRef"
        v-model:yield-code-name="yieldCodeName"
        v-model:yield-code-description="yieldCodeDescription"
        v-model:rules-to-save="rulesToSave"
        :face-list="yieldCodeViewModel.faceList"
        :pricing-rules="yieldCodeViewModel.pricingRules"
        :selected-rules="yieldCodeViewModel.selectedRules"
        :pricing-rate-code="yieldCodeViewModel.pricingRateCode"
        :record-id="recordId"
        :is-dirty="hasEditMadeChanges"
        :new-id="recordId"
      />
    </template>
    <template #footer>
      <div class="flex items-center w-full p-4">
        <div class="flex-none flex items-center gap-3">
          <Checkbox v-model="continueAfterSave" input-id="create-more" :binary="true" />
          <label class="continue-label cursor-pointer" for="create-more">Continue editing yield code and testing yield
            rules</label>
        </div>
        <div class="grow" />
        <div class="flex-none flex items-center gap-3">
          <Button
            label="Cancel"
            class="justify-self-end"
            severity="secondary"
            @click="onClose"
          />
          <Button
            v-if="action === 'edit'"
            label="Save and Assign"
            class="justify-self-end"
            :disabled="shouldDisableEdit"
            @click="onSave(true)"
          />
          <Button
            v-if="action === 'create'"
            label="Create"
            class="justify-self-end"
            :disabled="!canSubmit"
            @click="onSave()"
          />
          <Button
            v-if="action === 'edit'"
            label="Save"
            class="justify-self-end"
            :disabled="shouldDisableEdit"
            @click="onSave()"
          />
        </div>
      </div>
    </template>
  </Modal>

  <Modal
    :visible="assignFaceModalVisible"
    title="Assign Yield Code"
    :subtitle="selectedRow.name"
    @on:close="onClose"
  >
    <template #body>
      <PricingRateCodeAssignment
        v-model:yield-code="selectedRow"
        v-model:assigned-set="assignedSet"
        v-model:unassigned-set="unassignedSet"
      />
    </template>
    <template #footer>
      <div class="flex items-center w-full p-4">
        <div class="grow" />
        <div class="flex-none flex items-center gap-3">
          <Button
            label="Cancel"
            class="justify-self-end"
            severity="secondary"
            @click="onClose"
          />
          <Button
            label="Assign Faces"
            class="justify-self-end"
            @click="onAssignFaceClick"
          />
        </div>
      </div>
    </template>
  </Modal>
  <ConfirmDialog />
  <Toast />
</template>

<style scoped>
  .continue-label {
    color: var(--black-48);
    font-size: 14px;
  }

    :deep(.p-button) {
    gap: 8px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    background: var(--primary-blue);
    color: var(--white);
    border-radius: 8px;
    box-shadow: 0 4px 8px -4px var(--black-15);
    text-transform: capitalize;

    &.p-button-secondary {
      background: var(--white);
      border: 1px solid var(--black-16);
      color: var(--black-80);
    }
  }

  :deep(.p-checkbox .p-checkbox-box.p-highlight) {
    border: none;
    background: var(--primary-blue);
  }

  .icon-button {
    background: none;
    box-shadow: none;
    border:none;
  }

    .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }

</style>
