<template>
    <div class="col-span-8 text-sm p-6 leading-6">
        <div>
            <div class=""><strong>{{ ae.user_name }}</strong> / {{ ae.user_title }} / {{ ae.company }}</div>
            <div class="text-gray-400"><span class="text-main"><a v-bind:href="'mailto:' + ae.user_email">{{ ae.user_email }}</a></span>{{ phone }}{{ fax }}</div>
            <div class="text-gray-400">{{ ae.user_office_address }}</div>
        </div>
    </div>
    <div class="col-span-4 p-6 flex justify-end">
        <img class="max-h-20" v-bind:src="logo_url" alt='logo'>
    </div>
</template>

<script>
export default {
    props: {
        proposal: Object,
        logo_url: String,
    },
    computed: {
        ae: function() {
            return this.proposal.ae;
        },
        phone: function() {
            return (this.ae.user_mobile_phone.length > 0) ? ' / Cell: ' + this.ae.user_mobile_phone : ' / Office: ' + this.ae.user_office_phone;
        },
        fax: function() {
            return (this.ae.user_fax.length > 0) ? ' / Fax: ' + this.ae.user_fax : '';
        },
    }
};
</script>

<style scoped>
</style>
