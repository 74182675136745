<template>
    <h4 class="text-lg pt-1"><span class="text-main font-bold">{{title}}:</span> {{value}}</h4>
    <p class="text-sm text-gray-400">{{description}}</p>
</template>

<script>
export default {
    props: {
        'title': String,
        'value': String,
        'description': String,
    },
};
</script>

<style scoped>
</style>
