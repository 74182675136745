export const useFetch = () => {
  const getToken = () => {
    const str = `; ${document.cookie}`;
    const parts = str.split('; XSRF-TOKEN=');
    if(parts.length !== 2) return null;

    const encoded = parts.pop().split(';').shift();
    return decodeURIComponent(encoded);
  };

  const get = async <T extends any>(url: string): Promise<T> => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data as T;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const postOrPut = async <T extends any>(url: string, payload: any, type: 'POST' | 'PUT', formEncoded = false): Promise<T> => {
    const headers = {
      // eslint-disable-next-line @stylistic/js/quote-props
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // Even though fetch doesn't actually use XMLHttpRequest, we need to set this header because
      // Laravel uses it to determine whether to respond to some requests with JSON or a view/redirect
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': getToken(),
    };

    let body: string;
    if(formEncoded) {
      body = new URLSearchParams(payload).toString();
    } else {
      body = JSON.stringify(payload);
    }

    if(formEncoded) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }

    const response = await fetch(url, {
      method: type,
      credentials: 'same-origin',
      headers: headers,
      body: body,
    });

    if(response?.headers.has('content-type')) {
      if(response.headers.get('content-type').includes('text/html')) {
        return await response.text() as T;
      }
    }

    const data = await response.json();
    return data as T;
  };

  const destroy = async <T extends any>(url: string, payload?: any): Promise<T> => {
    const headers = {
      // eslint-disable-next-line @stylistic/js/quote-props
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getToken(),
    };

    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: headers,
      body: payload ? JSON.stringify(payload) : '',
    });

    const data = await response.json();

    return data as T;
  };

  const put = async<T extends any>(url: string, payload: any, formEncoded = false): Promise<T> => postOrPut<T>(url, payload, 'PUT', formEncoded) as T;
  const post = async<T extends any>(url: string, payload: any, formEncoded = false): Promise<T> => postOrPut<T>(url, payload, 'POST', formEncoded) as T;

  return {
    destroy,
    get,
    post,
    put,
  };
};
