<template>
    <div class="max-w-3xl mx-auto" v-cloak>
        <div class="grid grid-cols-12">

            <div class="col-span-12 mt-4 pt-2">
                <h1 class="text-2xl font-bold">{{ inv }}</h1>
            </div>

            <div class="col-span-12 pt-2">
                <h1 class="text-sm text-gray-500">{{ address }}</h1>
            </div>

            <div class="col-span-6 pt-4">
                <h1 class="text-sm font-bold">Metric</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm font-bold">{{ firstMonthName }}</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm font-bold">{{ secondMonthName }}</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm font-bold">{{ thirdMonthName }}</h1>
            </div>

            <div class="col-span-12 pt-4">
                <div class="border-t-2 border-gray-300"></div>
            </div>

            <div class="col-span-6 pt-4">
                <h1 class="text-sm">Revenue Budget</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm text-slate-500">${{ firstMonthBudget }}</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm text-slate-500">${{ secondMonthBudget }}</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 class="text-sm text-slate-500">${{ thirdMonthBudget }}</h1>
            </div>

            <div class="col-span-12 pt-4">
                <div class="border-t border-gray-300"></div>
            </div>

            <div class="col-span-6 pt-4">
                <h1 class="text-sm">Projected Revenue</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(firstMonthBudget, firstMonthRevenue)" class="text-sm text-green-500">
                    {{ this.dollarUS.format(firstMonthRevenue) }}
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ this.dollarUS.format(firstMonthRevenue) }}
                </h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(secondMonthBudget, secondMonthRevenue)" class="text-sm text-green-500">
                    {{ this.dollarUS.format(secondMonthRevenue) }}
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ this.dollarUS.format(secondMonthRevenue) }}
                </h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(thirdMonthBudget, thirdMonthRevenue)" class="text-sm text-green-500">
                    {{ this.dollarUS.format(thirdMonthRevenue) }}
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ this.dollarUS.format(thirdMonthRevenue) }}
                </h1>
            </div>

            <div class="col-span-12 pt-4">
                <div class="border-t border-gray-300"></div>
            </div>

            <div class="col-span-6 pt-4">
                <h1 class="text-sm">% Over/Under Budget</h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(firstMonthBudget, firstMonthRevenue)" class="text-sm text-green-500">
                    {{ calculatePercentDifference(firstMonthBudget, firstMonthRevenue) }}% Over
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ calculatePercentDifference(firstMonthBudget, firstMonthRevenue) }}% Under
                </h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(secondMonthBudget, secondMonthRevenue)" class="text-sm text-green-500">
                    {{ calculatePercentDifference(secondMonthBudget, secondMonthRevenue) }}% Over
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ calculatePercentDifference(secondMonthBudget, secondMonthRevenue) }}% Under
                </h1>
            </div>
            <div class="col-span-2 pt-4">
                <h1 v-if="isGreaterThanBudget(thirdMonthBudget, thirdMonthRevenue)" class="text-sm text-green-500">
                    {{ calculatePercentDifference(thirdMonthBudget, thirdMonthRevenue) }}% Over
                </h1>
                <h1 v-else class="text-sm text-red-600">
                    {{ calculatePercentDifference(thirdMonthBudget, thirdMonthRevenue) }}% Under
                </h1>
            </div>

            <div class="col-span-12 pt-4">
                <div class="border-t border-gray-300"></div>
            </div>

            <div class="col-span-2 pt-4">
                <h1 class="text-sm text-center font-bold py-2">Offered Rate $</h1>
            </div>

            <div class="col-span-2 pt-4">
                <div class="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                    <input v-model="originalOffer" type="number" min="0" name="offeredRate" id="offeredRate" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" />
                </div>
            </div>

            <div class="col-span-4 px-4 pt-3">
                <p class="text-xs text-slate-500 py-2">({{ calculatePercentDiscount(originalOffer, rateCard) }}% discount from rate card of ${{ rate }})</p>
            </div>

            <div class="col-span-2 pt-4">
            <button @click="cancel()" type="button" class="inline-flex items-center rounded-md border-2 bg-white px-4 py-2 text-sm font-medium leading-4 text-black shadow-sm hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Cancel
            </button>
            </div>

            <div class="col-span-2 pt-4">
            <button @click="saveRate(originalOffer)" type="button" class="inline-flex items-center rounded-md border-2 bg-indigo-600 px-4 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                Save Rate
            </button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: [
        'invNumber',
        'invDescription',
        'offer',
        'rate',
        'firstMonth',
        'secondMonth',
        'thirdMonth',
        'lineTotals',
        'budgetList',
        'revenueList',
    ],
    data: function() {
        let revenueKeys = Object.keys(this.revenueList);
        let budgetKeys = Object.keys(this.budgetList);
        let dollarUS = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return {
            inv: this.invNumber,
            address: this.invDescription,
            originalOffer: this.offer,
            rateCard: this.rate,
            firstMonthName: this.firstMonth,
            secondMonthName: this.secondMonth,
            thirdMonthName: this.thirdMonth,
            immutableFirstRevenue: Math.round(this.revenueList[revenueKeys[0]]),
            immutableSecondRevenue: Math.round(this.revenueList[revenueKeys[1]]),
            immutableThirdRevenue: Math.round(this.revenueList[revenueKeys[2]]),
            firstMonthBudget: Math.round(this.budgetList[budgetKeys[0]]),
            secondMonthBudget: Math.round(this.budgetList[budgetKeys[1]]),
            thirdMonthBudget: Math.round(this.budgetList[budgetKeys[2]]),
            firstMonthRevenue: Math.round(this.revenueList[revenueKeys[0]]),
            secondMonthRevenue: Math.round(this.revenueList[revenueKeys[1]]),
            thirdMonthRevenue: Math.round(this.revenueList[revenueKeys[2]]),
            lineOriginalTotals: this.lineTotals,
            dollarUS: dollarUS,
        }
    },

    computed: {
    },
    watch: {
        originalOffer(newOgOffer) {
            let lineKeys = Object.keys(this.lineOriginalTotals);
            this.firstMonthRevenue = this.recalculateRevenue(this.immutableFirstRevenue, this.lineOriginalTotals[lineKeys[0]], newOgOffer, this.lineOriginalTotals[lineKeys[3]]);
            this.secondMonthRevenue = this.recalculateRevenue(this.immutableSecondRevenue, this.lineOriginalTotals[lineKeys[1]], newOgOffer, this.lineOriginalTotals[lineKeys[4]]);
            this.thirdMonthRevenue = this.recalculateRevenue(this.immutableThirdRevenue, this.lineOriginalTotals[lineKeys[2]], newOgOffer, this.lineOriginalTotals[lineKeys[5]]);
        },
    },
    methods: {
        calculatePercentDifference: function(budget, revenue) {
            if (revenue === 0) {
                return 0;
            }
            if (revenue !== 0 && budget === 0) {
                return 100;
            }
            return Math.abs((1 - (revenue / budget)) * 100).toFixed(2);
        },
        calculatePercentDiscount: function(offer, rate) {
            let percentDiscount = ((1 - (offer / rate)) * 100).toFixed(2);
            if (percentDiscount < 0)
            {
                return (0).toFixed(2);
            }
            return percentDiscount;
        },
        cancel: function() {
            parent.hideLegacyModalWindow('mySlider13');
        },
        isGreaterThanBudget: function(budget, revenue) {
            return revenue > budget;
        },
        recalculateRevenue: function(originalRevenue, panelPeriodRevenue, newOfferRate, daysInPeriod) {
            return Math.round((originalRevenue - panelPeriodRevenue) + (newOfferRate * daysInPeriod))
        },
        saveRate: function(rate) {
            let formattedRate = Math.round(rate).toFixed(2);
            parent.updateEvaluateRate(formattedRate);
            parent.showEvaluateToast();
        }
    },
    mounted: function() {
    }
};
</script>

<style scoped>
</style>
