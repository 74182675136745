<script setup lang="ts">
import { ref } from 'vue';
import DragHandleIcon from '@apparatix/icons/DragHandleIcon.vue';
import InfoIcon from '@apparatix/icons/InfoIcon.vue';

interface YieldCodeRuleProps {
  name: string;
  itemKey: string;
  description?: string;
}

const props = defineProps<YieldCodeRuleProps>();
const tooltipText = ref(props.description ?? 'No description available');
</script>

<template>
  <div :key="props.itemKey" class="yield-code-rule">
    <div class="drag-handle">
      <DragHandleIcon />
    </div>
    <div class="rule-name">
      {{ props.name }}
    </div>
    <div v-tooltip="`${tooltipText}`" class="rule-info">
      <InfoIcon />
    </div>
  </div>
</template>

<style scoped>
  .yield-code-rule {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--black-16);
    box-shadow: var(--input-box-shadow);
    background-color: var(--white);
    cursor: pointer;

    &:active {
      cursor: move;
    }

    .drag-handle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: var(--black-4);
      mix-blend-mode: multiply;
    }

    .rule-name {
      display: flex;
      flex-grow: 1;
      align-items: center;
      height: 48px;
      color: var(--black-80);
      font-size: 14px;
      font-weight: 500;
      padding-left: 16px;
      margin-right: 16px;
    }

    .rule-info {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
