import axios from 'axios';
import { AppStore } from '@apparatix/stores/AppStore';

const logoutUrl = '/logout';
const loginUrl = '/login';

export const useAppNavigation = () => {
  const syncOverflow = (el: Element, shouldOverflow: boolean): void => {
    const label = (el as HTMLElement)?.innerText;

    if (!label) return;

    if (shouldOverflow) {
      const visibleIdx = AppStore.navigationItems.findIndex(item => item.label === label);
      if (visibleIdx !== -1) {
        const tmp = AppStore.navigationItems[visibleIdx];
        AppStore.navigationItems[visibleIdx].isOverflowing = true;
        (el as HTMLElement).style.opacity = '0';
        AppStore.overflowNavigation.unshift(tmp);
      }

      return;
    }

    const overflowIdx = AppStore.overflowNavigation.findIndex(item => item.label === label);

    if (overflowIdx !== -1) {
      AppStore.overflowNavigation.splice(overflowIdx, 1);
      (el as HTMLElement).style.opacity = '1';
    }
  };

  const showPageNotImplemented = () => {
    AppStore.shouldShowDashboard = false;
    AppStore.showPageNotImplemented = true;
    AppStore.setActiveUrl('', '');
  };

  const logOut = () => {
    axios.post(logoutUrl).then(() => window.location.href = loginUrl)
      .catch(() => window.location.href = loginUrl);
  };

  const navigate = (parentLabel: string, childLabel?: string) => {
    const parent = AppStore.navigationItems.find(item => item.label.toLowerCase() === parentLabel.toLowerCase());
    if(!parent) {
      console.warn(`No page found with label '${parentLabel}'`);
      return;
    }

    AppStore.setActivePage(parent);
    if(!childLabel) {
      return;
    }

    const child = parent.children.find(item => item.label.toLowerCase() === childLabel.toLowerCase());
    if(child) {
      AppStore.setActiveSubPage(child);
    } else {
      console.warn(`No sub page found with label '${childLabel}'`);
    }
  };

  const gotoLoginPage = () => window.location.href = '/login';

  const gotoDashboard = (refresh: boolean = true) => {
    navigate('Dashboard');

    if(refresh) {
      setTimeout(() => AppStore.refreshDashboard(), 50);
    }
  };

  return {
    syncOverflow,
    showPageNotImplemented,
    logOut,
    gotoLoginPage,
    navigate,
    gotoDashboard,
  };

};
